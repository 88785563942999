import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Combobox } from "@headlessui/react";
import highlightWords from "highlight-words";

import { fetchSuggestedPostcodes } from "../../methods/appAPICalls";

// const postcodeRegex =
//   /^(([A-Z][A-HJ-Y]?\d[A-Z\d]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?\d[A-Z]{2}|BFPO ?\d{1,4}|(KY\d|MSR|VG|AI)[ -]?\d{4}|[A-Z]{2} ?\d{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/;

const PostcodeAutocomplete = ({
  onSelect,
  placeholder,
  defaultValue,
  required,
  className,
}) => {
  const [postcode, setPostcode] = useState(
    typeof defaultValue === "number" ? "" : defaultValue || ""
  );
  const [searchText, setSearchText] = useState("");
  const [suggestedPostcodes, setSuggestedPostcodes] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    setPostcode(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    // TODO: Clean up the postcode error related code.
    if (!searchText) {
      setError("");
    } else {
      fetchSuggestedPostcodes(searchText)
        .then((result) => {
          const noSpaceSearch = searchText.replace(" ", "");
          const postcodeExists = result.find(
            (item) =>
              item.id.replace(" ", "").toLowerCase() ===
              noSpaceSearch.toLowerCase()
          );

          if (postcodeExists) {
            onSelect(postcodeExists.id);
            setPostcode(postcodeExists.id);
          }
          setSuggestedPostcodes(result);
        })
        .catch((err) => {
          console.log({ err });
          setSearchText("");
          setSuggestedPostcodes([]);
          setError(err.message);
        });
    }
  }, [searchText]);

  const handleCancel = () => {
    setSearchText("");
    setPostcode("");
    setError("");
    setSuggestedPostcodes([]);
  };

  const handleSearch = () => {
    setPostcode("");
    setSearchText("");
  };

  useEffect(() => {
    console.log("Postcode changed");
    if (postcode && onSelect) {
      // TODO: We need to insert a check here that just says "If it looks right, submit" event
      // if there is no geodata found.
      onSelect(
        postcode,
        suggestedPostcodes.filter((obj) => obj.id === postcode)[0]
      );
    }
  }, [postcode]);

  const hasValue = error || postcode || searchText;

  return (
    <div className="w-full">
      <Combobox
        name="postcode search"
        value={postcode}
        onChange={(value) => setPostcode(value)}
      >
        <div className="w-full flex">
          <Combobox.Input
            aria-label="Postcode search"
            required={required || false}
            className="text-sm shadow-sm items-center space-y-2 p-2 w-full flex justify-start border border-greyClear rounded-l-md rounded-r-none bg-white"
            displayValue={
              postcode?.toString()?.toUpperCase() || searchText?.toUpperCase()
            }
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder={placeholder}
          />
          {hasValue && (
            <button
              type="button"
              onClick={handleCancel}
              className="cursor-pointer px-2 bg-primary rounded-r-md shadow-sm"
            >
              <img src="/icons/close.svg" alt="clear search" />
            </button>
          )}
          {!hasValue && (
            <button
              type="button"
              className="cursor-pointer px-2 bg-primary rounded-r"
              onClick={handleSearch}
            >
              <img src="/icons/search.svg" alt="search" />
            </button>
          )}
        </div>
        {error && (
          <p className="text-red-500 text-sm bg-white rounded mt-1 px-2 lg:-mb-1">
            {error}
          </p>
        )}
        {suggestedPostcodes?.length > 0 && (
          <Combobox.Options className="w-full dropdown-container relative space-y-1 bg-white z-50 shadow-lg rounded overflow-hidden">
            {suggestedPostcodes.map((obj) => (
              <Combobox.Option
                value={obj.id}
                key={obj.id}
                className="dropdown-option"
              >
                {({ active }) => {
                  const chunks = highlightWords({
                    text: obj.id,
                    query: searchText,
                  });
                  return (
                    <div
                      className={`py-1 px-2 cursor-pointer flex justify-between text-sm hover:text-red hover:bg-indigo-600 hover:text-white duration-150 transition-colors ${
                        active ? "bg-indigo-600 text-white" : ""
                      }`}
                    >
                      <div>
                        {chunks.map((chunk) => (
                          <span
                            key={chunk.key}
                            className={chunk.match ? "font-bold" : ""}
                          >
                            {chunk.text}
                          </span>
                        ))}
                      </div>
                      {obj?.names && (
                        <span className="pr-1">
                          <i>{obj.names.ward}</i>, <i>{obj.names.laua}</i>
                        </span>
                      )}
                    </div>
                  );
                }}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </Combobox>
    </div>
  );
};

PostcodeAutocomplete.propTypes = {
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

PostcodeAutocomplete.defaultProps = {
  placeholder: "Search for a postcode",
  defaultValue: undefined,
  required: false,
  className: undefined,
};

export default PostcodeAutocomplete;
