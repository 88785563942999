import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Combobox } from "@headlessui/react";
import highlightWords from "highlight-words";
import { stringValueExists } from "@/methods/utilities";

import { fetchSuggestedSchools, fetchSchoolFromId } from "../../methods/appAPICalls";

const SchoolAutocomplete = ({
  onSelect,
  placeholder,
  defaultValue,
  required,
  className,
}) => {
  const [school, setSchool] = useState(undefined);
  const [searchText, setSearchText] = useState("");
  const [suggested, setSuggested] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    if (stringValueExists(defaultValue)) {
      fetchSchoolFromId(defaultValue)
        .then((res) => {
          setSchool({
            id: defaultValue,
            schoolName: res.schoolName,
          });
        })
        .catch((err) => {
          console.log({ err });
          setError("Error fetching school name", err.message);
          setSearchText("");
          setSuggested([]);
        });
    } else {
      setSchool(undefined);
    }
  }, [defaultValue]);

  useEffect(() => {
    let ignore = false;
    if (searchText && searchText.trim().length > 0) {
      fetchSuggestedSchools(searchText)
        .then((data) => {
          if (!ignore) {
            setSuggested(data.schools);
          }
        })
        .catch((err) => {
          console.log({ err });
          setError(err.message);
          setSearchText("");
          setSuggested([]);
        });
    } else {
      setSuggested([]);
    }
    return () => {
      ignore = true;
    };
  }, [searchText]);

  const handleCancel = () => {
    setSearchText("");
    setSchool(undefined);
    setError(undefined);
    setSuggested([]);
  };

  const handleSearch = () => {
    setSearchText("");
  };

  const handleChange = (inputText) => {
    if (error?.length > 0) {
      setError("");
    }
    setSearchText(inputText);
  }

  const handleSelect = (value) => {
    if (onSelect) {
      onSelect(value?._id);
    }
    setSchool({
      id: value?._id,
      schoolName: value?.schoolName,
    });
    setSuggested([]);
  };

  const hasValue = error || searchText || school?.schoolName;

  return (
    <div className={classNames("w-full", className)}>
      <Combobox name="school search" value={school?.schoolName} onChange={(value) => handleSelect(value)}>
        <div className="w-full flex">
          <Combobox.Input
            aria-label="School Search"
            required={required || false}
            className="text-sm shadow-sm items-center space-y-2 p-2 w-full flex justify-start border border-gray-300 rounded-l-md rounded-r-none bg-white"
            displayValue={school?.schoolName || searchText}
            onChange={(e) => handleChange(e.target.value)}
            type="text"
            placeholder={placeholder}
          />
          {hasValue && (
            <button
              type="button"
              onClick={handleCancel}
              className="cursor-pointer px-2 bg-primary rounded-r-md shadow-sm"
            >
              <img src="/icons/close.svg" alt="clear search" />
            </button>
          )}
          {!hasValue && (
            <button
              type="button"
              className="cursor-pointer px-2 bg-primary rounded-r"
              onClick={handleSearch}
            >
              <img src="/icons/search.svg" alt="search" />
            </button>
          )}
        </div>
        {error && (
          <p className="text-red-500 text-sm bg-white rounded mt-1 px-2 lg:-mb-1">
            {error}
          </p>
        )}
        {suggested?.length > 0 && (
          <Combobox.Options className="w-full dropdown-container relative space-y-1 bg-white z-50 shadow-lg rounded overflow-hidden">
            {suggested?.map((obj) => (
              <Combobox.Option
                className="dropdown-option"
                value={obj}
                key={obj._id}
              >
                {({ active }) => {
                  const chunks = highlightWords({
                    text: obj.schoolName,
                    query: searchText,
                  });
                  return (
                    <div
                      className={`py-1 px-2 cursor-pointer flex justify-between text-sm hover:text-red hover:shadow-md hover:text-white duration-150 transition-colors ${
                        active ? "bg-primary text-white" : ""
                      }`}
                    >
                      <div>
                        {chunks.map((chunk) => (
                          <span
                            key={chunk.key}
                            className={chunk.match ? "font-bold" : ""}
                          >
                            {chunk.text}
                          </span>
                        ))}
                      </div>
                      <span className="pr-1">
                        <i>{obj["DistrictAdministrative (name)"]}</i>
                      </span>
                    </div>
                  );
                }}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </Combobox>
    </div>
  );
};

SchoolAutocomplete.propTypes = {
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

SchoolAutocomplete.defaultProps = {
  placeholder: "Search for a school",
  defaultValue: undefined,
  required: false,
  className: undefined,
};

export default SchoolAutocomplete;
